div.layout-global {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

div.layout-global div.layout-main {
    display: flex;
    flex-direction: column;
    flex: 1;
}

div.layout-global div.layout-main div.layout-header {
    padding-left: 10px;
}

div.layout-global div.layout-main div.layout-header .layout-top-banner {
    height: 38px;
}

div.layout-global div.layout-main div.layout-header div.layout-locale-selector {
    text-align: right;
}

div.layout-global div.layout-main div.layout-header {
    flex: 0;
}

div.layout-global div.layout-main div.layout-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

div.layout-global div.layout-main  .logo {
    width: 1.5em;
    padding-right: 0.2em;
}

div.layout-global div.layout-main h1.ui.medium {
    font-size: 1.2em;
    text-align: left;
}