div.layout-inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
}

div.layout-inner div.layout-inner-header {
    flex: 0;
}

div.layout-inner div.layout-inner-header a.layout-inner-sidebar-trigger {
    position: absolute;
    left: 15px;
    cursor: pointer;
}

div.layout-inner div.mobile-layout-menubox {
    padding: 15px;
}