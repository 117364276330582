.record-playback {
    position: fixed !important;
    height: 55px;
    text-align: center;
    display: flex;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid #CCC;
    border-radius: 27px;
    padding: 0;
    left: 10px;
    right: 6px;
}

.record-playback.sticky {
    bottom: 10px;
    z-index: 999;
}

.record-playback .record-playback-play {
    flex: 0;
    margin-left: -1px;
}

.record-playback.footer .record-playback-play .ui.button:focus,
.record-playback.footer .record-playback-play .ui.button:hover {
    background: transparent !important;
}

.record-playback.sticky .record-playback-play .ui.button {
    border: 1px solid #CCCCCC;
}

.record-playback .record-playback-timeline {
    flex: 1;
    margin: 20px 15px 0 5px;
}

.record-playback .record-playback-timeline .rc-slider {
    z-index: 99;
    padding-bottom: 0;
    margin-bottom: 0;
}

.record-playback .record-playback-timeline .rc-slider.rc-slider-disabled {
    background: transparent;
}

.record-playback .record-playback-timeline .rc-slider.rc-slider-disabled .rc-slider-handle {
    cursor: pointer;
}

.record-playback .record-playback-timeline .rc-slider .rc-slider-rail {
    background-color: #a0a0a0 !important;
}

.record-playback .record-playback-timeline .rc-slider .rc-slider-track {
    background-color: #ff1744;
}

.record-playback .record-playback-timeline .rc-slider .rc-slider-handle {

}

.record-playback .record-playback-timeline .progress {
    z-index: 98;
    height: 2px;
    margin: -5px 0 0 0 !important;
}

.record-playback .record-playback-timeline .progress .bar {
    height: 2px;
    margin: 0 !important;
}

.record-playback .record-playback-infos {
    padding: 18px 15px 0 0;
}

.record-playback .video-draggable {
    z-index: 1000;
    position: absolute !important;
    top: -420px;
    right: 10px;
}

.record-playback .video-draggable .controls {
    z-index: 1000;
    position: absolute;
    right: 3px;
    top: 5px;
}

.record-playback .video-controls {
    margin-top: 9px;
    margin-right: 5px;
}