.dropzone {
    min-height: 100px;
    width: 50%;
    height: 100%;
    margin: 0 auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    justify-content: center;
}

.dropzone.active {
    border-color: #2196f3
}

.dropzone.accept {
    border-color: #00e676
}

.dropzone.reject {
    border-color: #ff1744
}
