.home-header .logo {
    width: 1.5em;
    padding-right: 0.2em;
}

.home-header h1.ui.huge {
    font-size: 4em
}

.home-header button.login-button {
    margin-top: 1em;
}

.ui.inverted.segment.home-header {
    background: url("images/table.jpg");
    background-size: cover;    
    background-position: center;
    box-shadow:inset 0 0 0 1000px rgba(128, 139, 150, 0.8);
}
