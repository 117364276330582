.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.ui.inverted.segment.fancy-header {
  background-size: cover;
  background: url("media/banner.png") center;
  box-shadow:inset 0 0 0 1000px rgba(128, 139, 150, 0.6);
}