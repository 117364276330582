.records-search-menu {
    margin-bottom: 10px;
}

.records-search-menu .ui.vertical.menu {
    width: 100%;
}

.records-search-menu .field {
    margin-bottom: 10px;
}

.records-search-menu .field div.ui.input,
.records-search-menu .field div.ui.dropdown {
    width: 100%
}

.records-search-menu .field.actions {
    text-align: center;
}