.notifications {
    position: fixed;
    z-index: 2060;
    margin-top: 20px;
    left: 50%;
    height: 0;
    width: 600px !important;
    margin-left: -300px !important;
}

.notifications .ui.message.notification {
    margin-bottom: 10px;
}

@media (min-width: 320px) {
    .notifications {
        width: 300px !important;
        margin-left: -150px !important;
    }
}

@media (min-width: 480px) {
    .notifications {
        width: 450px !important;
        margin-left: -225px !important;
    }
}

@media (min-width: 600px) {
    .notifications {
        width: 550px !important;
        margin-left: -275px !important;
    }
}

@media (min-width: 801px) {
    .notifications {
        width: 600px !important;
        margin-left: -300px !important;
    }
}

@media (min-width: 1025px) {
    .notifications {
        width: 600px !important;
        margin-left: -300px !important;
    }
}

@media (min-width: 1281px) {
    .notifications {
        width: 600px !important;
        margin-left: -300px !important;
    }
}
