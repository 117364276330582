div.layout-inner div.layout-inner-mobile {
    flex: 1;
    display: flex;
    flex-direction: column;
}

div.layout-inner div.layout-inner-mobile div.ui.segment.pushable {
    border: none;
    flex: 1;
    transform: inherit;
}

div.layout-inner div.layout-inner-mobile div.pusher {
    padding: 20px 10px;
}

div.layout-inner div.layout-inner-mobile .ui.large.vertical.menu {
    width: 100%;
    max-width: 100%;
}