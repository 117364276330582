.record-view-panel {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
}

.record-view-panel .record-view-details {
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.record-view-panel .record-view-details  .ui.attached.tabular.menu {
    flex: 0;
    display: flex;
}

.record-view-panel .record-view-details  .ui.segment.tab {
    flex: 1;
    display: flex;
}

.record-view-panel .record-view-details .dimmer.active {
    z-index: 999 !important;
}

.record-view-panel .record-view-upload {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.record-view-panel .record-view-upload-header {
    display: flex;
    flex-direction: column;
    flex: 0;
    padding-bottom: 0px !important;
}

.record-view-panel .record-view-upload-header h3 {
    margin-top: 5px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.record-view-panel .record-view-upload-header .record-pre-remove {
    position: absolute;
    right: 0px;
}

.record-view-panel .ui.grid {
    flex: 1;
}

.record-view-panel .ui.container.record-uploader,
.record-view-panel .ui.container.record-uploader div.ui.segment,
.record-view-panel .ui.container.record-uploader div.ui.segment div.ui.container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.record-view-panel .ui.container.record-uploader div.ui.segment div.ui.container.upload-progress {
    display: flex;
    flex: 0 1;
}

.record-view-panel .ui.segment.record-processing {
    display: flex;
    flex: 1;
}

.record-view-panel .ui.segment.record-view-error {
    flex: 1;
}
