.transcript {
    margin-bottom: 20px;
    flex: 1;
}

.transcript .avatar {
    height: 20px !important;
}

.transcript .transcript-segment > td span.highlight {
    background-color: yellow;
}

.transcript table.ui.table .transcript-segment > td {
    font-size: 14px;
    text-align: justify;
}

.transcript table.ui.table .transcript-segment > td {
    padding: 5px 10px;
}

.transcript table.ui.table .transcript-segment > td:last-child {
    font-size: 10px;
    color: gray;
    text-align: center;
    width: 90px;
}

.transcript table.ui.table .transcript-segment > td:last-child .icon {
    cursor: pointer;
}

.transcript table.ui.table .transcript-segment > td:first-child {
    min-width: 100px;
    text-align: center;
}

.transcript table.ui.table .transcript-segment.transcript-segment-speaker-change > td {
    border-top-width: 3px;
}

.transcript table.ui.table .transcript-segment.editing:hover {
    background-color: #fdf8ec;
}

.transcript table.ui.table .transcript-segment .word-low-confidence {
    text-decoration:underline;
    text-decoration-style: dotted;
    text-decoration-color: orange;
}

.transcript .segment-edit-text {
    padding: 4px;
    border: 2px dashed #0090ff;
}

.speaker-edit-cancel {
    text-align: right;
}