div.layout-inner div.layout-inner-desktop {
    flex: 1;
    display: flex;
}

div.layout-inner div.layout-inner-desktop div.layout-inner-desktop-menu {
    flex: 0 1;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 290px;
    min-width: 260px;
}

div.layout-inner div.layout-inner-desktop div.layout-inner-desktop-menu div.ui.card {
    width: 100%;
}

div.layout-inner div.layout-inner-desktop div.layout-inner-desktop-menu div.ui.menu {
    width: 100%;
}

div.layout-inner div.layout-inner-desktop div.layout-inner-desktop-content {
    flex: 1;
    padding: 10px;
}

